import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form } from "@ster/ster-toolkit";
import { memo, useMemo } from "react";

import {
  GroupedPackageRadio,
  Package,
  PackagePropertiesRadio,
  PackageType,
} from "../../../../api";
import PackageSwitch from "./PackageSwitch";

const ChannelChoice = memo(
  ({
    groupedPackage: { properties: packagesProperties },
    selectedPackageProperties,
    selectedPackage,
  }: {
    selectedPackageProperties?: PackagePropertiesRadio;
    groupedPackage: GroupedPackageRadio;
    selectedPackage?: Package;
  }) => {
    const { i18n } = useLingui();

    const isSeniors = useMemo(
      () => selectedPackage?.type === PackageType.Seniors,
      [selectedPackage?.type]
    );

    const label = useMemo(() => {
      if (isSeniors) {
        return i18n._(t`NPO Radio 1 uitsluiten`);
      }
      return i18n._(t`Zender uitsluiten`);
    }, [i18n, isSeniors]);

    const helpText = useMemo(() => {
      if (isSeniors) {
        return "";
      }
      return i18n._(t`Met deze optie kun je 1 zender uitsluiten.`);
    }, [i18n, isSeniors]);

    const packagesPropertiesWithChannelChoice = useMemo(
      () =>
        packagesProperties.find(({ hasChannelChoice }) => hasChannelChoice) !==
        undefined,
      [packagesProperties]
    );

    return (
      packagesPropertiesWithChannelChoice && (
        <Form.Item label={label} helpText={helpText}>
          <PackageSwitch
            name="hasChannelChoice"
            packagesProperties={packagesProperties}
            selectedPackageProperties={selectedPackageProperties}
          />
        </Form.Item>
      )
    );
  }
);

export default ChannelChoice;
