/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DateSpan,
  DateSpanFromJSON,
  DateSpanFromJSONTyped,
  DateSpanToJSON,
  DiscountSurtaxRule,
  DiscountSurtaxRuleFromJSON,
  DiscountSurtaxRuleFromJSONTyped,
  DiscountSurtaxRuleToJSON,
  InternetFormat,
  InternetFormatFromJSON,
  InternetFormatFromJSONTyped,
  InternetFormatToJSON,
  PackageType,
  PackageTypeFromJSON,
  PackageTypeFromJSONTyped,
  PackageTypeToJSON,
  TargetGroup,
  TargetGroupFromJSON,
  TargetGroupFromJSONTyped,
  TargetGroupToJSON,
  TimeBand,
  TimeBandFromJSON,
  TimeBandFromJSONTyped,
  TimeBandToJSON,
} from "./";

/**
 *
 * @export
 * @interface Package
 */
export interface Package {
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  hasBreakSelection: boolean;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  marketIndex: number;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  monthIndex: number;
  /**
   *
   * @type {Array<DateSpan>}
   * @memberof Package
   */
  periods: Array<DateSpan>;
  /**
   *
   * @type {Array<DateSpan>}
   * @memberof Package
   */
  salesLockPeriods: Array<DateSpan>;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  productIndex: number;
  /**
   *
   * @type {Array<TargetGroup>}
   * @memberof Package
   */
  targetGroups: Array<TargetGroup>;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  minDays: number;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  minGrp?: number;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  readonly yearBaseRate: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Package
   */
  readonly weekDays?: Array<number> | null;
  /**
   *
   * @type {Array<TimeBand>}
   * @memberof Package
   */
  readonly timeBands?: Array<TimeBand> | null;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  discountPerc: number;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  dynamicMarketIndex: number;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  canSelectPreferredPosition: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  hasSpread: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  readonly hasConsecutiveHours: boolean;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  minDailyHours: number;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  minDaysNoWeekly: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Package
   */
  spotLengths?: Array<number> | null;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  costPerSpot?: number;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  digitalMarketIndexPublished?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  readonly isSpreadEnabled: boolean;
  /**
   *
   * @type {Array<DiscountSurtaxRule>}
   * @memberof Package
   */
  discountSurtaxes?: Array<DiscountSurtaxRule> | null;
  /**
   *
   * @type {string}
   * @memberof Package
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof Package
   */
  name: string;
  /**
   *
   * @type {Array<InternetFormat>}
   * @memberof Package
   */
  internetFormat?: Array<InternetFormat> | null;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  readonly isYourTime: boolean;
  /**
   *
   * @type {string}
   * @memberof Package
   */
  yourTimeType?: string | null;
  /**
   *
   * @type {number}
   * @memberof Package
   */
  preferredPositionSurcharge?: number;
  /**
   *
   * @type {PackageType}
   * @memberof Package
   */
  type?: PackageType;
  /**
   *
   * @type {boolean}
   * @memberof Package
   */
  readonly isCombi: boolean;
}

export function PackageFromJSON(json: any): Package {
  return PackageFromJSONTyped(json, false);
}

export function PackageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Package {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    hasBreakSelection: json["hasBreakSelection"],
    marketIndex: json["marketIndex"],
    monthIndex: json["monthIndex"],
    periods: (json["periods"] as Array<any>).map(DateSpanFromJSON),
    salesLockPeriods: (json["salesLockPeriods"] as Array<any>).map(
      DateSpanFromJSON
    ),
    productIndex: json["productIndex"],
    targetGroups: (json["targetGroups"] as Array<any>).map(TargetGroupFromJSON),
    minDays: json["minDays"],
    minGrp: !exists(json, "minGrp") ? undefined : json["minGrp"],
    yearBaseRate: json["yearBaseRate"],
    weekDays: !exists(json, "weekDays") ? undefined : json["weekDays"],
    timeBands: !exists(json, "timeBands")
      ? undefined
      : json["timeBands"] === null
        ? null
        : (json["timeBands"] as Array<any>).map(TimeBandFromJSON),
    discountPerc: json["discountPerc"],
    dynamicMarketIndex: json["dynamicMarketIndex"],
    canSelectPreferredPosition: json["canSelectPreferredPosition"],
    hasSpread: json["hasSpread"],
    hasConsecutiveHours: json["hasConsecutiveHours"],
    minDailyHours: json["minDailyHours"],
    minDaysNoWeekly: json["minDaysNoWeekly"],
    spotLengths: !exists(json, "spotLengths") ? undefined : json["spotLengths"],
    costPerSpot: !exists(json, "costPerSpot") ? undefined : json["costPerSpot"],
    digitalMarketIndexPublished: !exists(json, "digitalMarketIndexPublished")
      ? undefined
      : json["digitalMarketIndexPublished"],
    isSpreadEnabled: json["isSpreadEnabled"],
    discountSurtaxes: !exists(json, "discountSurtaxes")
      ? undefined
      : json["discountSurtaxes"] === null
        ? null
        : (json["discountSurtaxes"] as Array<any>).map(
            DiscountSurtaxRuleFromJSON
          ),
    code: json["code"],
    name: json["name"],
    internetFormat: !exists(json, "internetFormat")
      ? undefined
      : json["internetFormat"] === null
        ? null
        : (json["internetFormat"] as Array<any>).map(InternetFormatFromJSON),
    isYourTime: json["isYourTime"],
    yourTimeType: !exists(json, "yourTimeType")
      ? undefined
      : json["yourTimeType"],
    preferredPositionSurcharge: !exists(json, "preferredPositionSurcharge")
      ? undefined
      : json["preferredPositionSurcharge"],
    type: !exists(json, "type") ? undefined : PackageTypeFromJSON(json["type"]),
    isCombi: json["isCombi"],
  };
}

export function PackageToJSON(value?: Package | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    hasBreakSelection: value.hasBreakSelection,
    marketIndex: value.marketIndex,
    monthIndex: value.monthIndex,
    periods: (value.periods as Array<any>).map(DateSpanToJSON),
    salesLockPeriods: (value.salesLockPeriods as Array<any>).map(
      DateSpanToJSON
    ),
    productIndex: value.productIndex,
    targetGroups: (value.targetGroups as Array<any>).map(TargetGroupToJSON),
    minDays: value.minDays,
    minGrp: value.minGrp,
    discountPerc: value.discountPerc,
    dynamicMarketIndex: value.dynamicMarketIndex,
    canSelectPreferredPosition: value.canSelectPreferredPosition,
    hasSpread: value.hasSpread,
    minDailyHours: value.minDailyHours,
    minDaysNoWeekly: value.minDaysNoWeekly,
    spotLengths: value.spotLengths,
    costPerSpot: value.costPerSpot,
    digitalMarketIndexPublished: value.digitalMarketIndexPublished,
    discountSurtaxes:
      value.discountSurtaxes === undefined
        ? undefined
        : value.discountSurtaxes === null
          ? null
          : (value.discountSurtaxes as Array<any>).map(
              DiscountSurtaxRuleToJSON
            ),
    code: value.code,
    name: value.name,
    internetFormat:
      value.internetFormat === undefined
        ? undefined
        : value.internetFormat === null
          ? null
          : (value.internetFormat as Array<any>).map(InternetFormatToJSON),
    yourTimeType: value.yourTimeType,
    preferredPositionSurcharge: value.preferredPositionSurcharge,
    type: PackageTypeToJSON(value.type),
  };
}
