/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum PackageType {
  RunOnSite = "RunOnSite",
  RunOnNetwork = "RunOnNetwork",
  HouseDisplay = "HouseDisplay",
  TourDeFrance = "TourDeFrance",
  Top2000 = "Top2000",
  AlgemeenNut = "AlgemeenNut",
  NpoPromo = "NpoPromo",
  Specifiek = "Specifiek",
  Seniors = "Seniors",
  Combi50Plus = "Combi50Plus",
  FunXDisplay = "FunXDisplay",
  SpecifiekCultuur = "SpecifiekCultuur",
  SeriousRequest = "SeriousRequest",
  Spits = "Spits",
}

export function PackageTypeFromJSON(json: any): PackageType {
  return PackageTypeFromJSONTyped(json, false);
}

export function PackageTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PackageType {
  return json as PackageType;
}

export function PackageTypeToJSON(value?: PackageType | null): any {
  return value as any;
}
