/* tslint:disable */
/* eslint-disable */
/**
 * Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  OutputPricePerMonth,
  OutputPricePerMonthFromJSON,
  OutputPricePerMonthFromJSONTyped,
  OutputPricePerMonthToJSON,
} from "./";

/**
 *
 * @export
 * @interface TargetGroup
 */
export interface TargetGroup {
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  costPerGrp: number;
  /**
   *
   * @type {Array<OutputPricePerMonth>}
   * @memberof TargetGroup
   */
  outputPriceWithoutSpotLengthIndex: Array<OutputPricePerMonth>;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  costPerSpot?: number;
  /**
   *
   * @type {string}
   * @memberof TargetGroup
   */
  intomartTargetGroupId: string;
  /**
   *
   * @type {string}
   * @memberof TargetGroup
   */
  targetGroupId: string;
  /**
   *
   * @type {string}
   * @memberof TargetGroup
   */
  targetGroupName: string;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  targetGroupIndex: number;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  transitionIndex: number;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  targetGroupIndexWithoutTransitionIndex: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TargetGroup
   */
  channels: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  cpmPrice: number;
  /**
   *
   * @type {boolean}
   * @memberof TargetGroup
   */
  hasChannelChoice: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof TargetGroup
   */
  disabledDeseletionChannels: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TargetGroup
   */
  maximumChannelDeselection?: number | null;
}

export function TargetGroupFromJSON(json: any): TargetGroup {
  return TargetGroupFromJSONTyped(json, false);
}

export function TargetGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TargetGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    costPerGrp: json["costPerGrp"],
    outputPriceWithoutSpotLengthIndex: (
      json["outputPriceWithoutSpotLengthIndex"] as Array<any>
    ).map(OutputPricePerMonthFromJSON),
    costPerSpot: !exists(json, "costPerSpot") ? undefined : json["costPerSpot"],
    intomartTargetGroupId: json["intomartTargetGroupId"],
    targetGroupId: json["targetGroupId"],
    targetGroupName: json["targetGroupName"],
    targetGroupIndex: json["targetGroupIndex"],
    transitionIndex: json["transitionIndex"],
    targetGroupIndexWithoutTransitionIndex:
      json["targetGroupIndexWithoutTransitionIndex"],
    channels: json["channels"],
    cpmPrice: json["cpmPrice"],
    hasChannelChoice: json["hasChannelChoice"],
    disabledDeseletionChannels: json["disabledDeseletionChannels"],
    maximumChannelDeselection: !exists(json, "maximumChannelDeselection")
      ? undefined
      : json["maximumChannelDeselection"],
  };
}

export function TargetGroupToJSON(value?: TargetGroup | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    costPerGrp: value.costPerGrp,
    outputPriceWithoutSpotLengthIndex: (
      value.outputPriceWithoutSpotLengthIndex as Array<any>
    ).map(OutputPricePerMonthToJSON),
    costPerSpot: value.costPerSpot,
    intomartTargetGroupId: value.intomartTargetGroupId,
    targetGroupId: value.targetGroupId,
    targetGroupName: value.targetGroupName,
    targetGroupIndex: value.targetGroupIndex,
    transitionIndex: value.transitionIndex,
    targetGroupIndexWithoutTransitionIndex:
      value.targetGroupIndexWithoutTransitionIndex,
    channels: value.channels,
    cpmPrice: value.cpmPrice,
    hasChannelChoice: value.hasChannelChoice,
    disabledDeseletionChannels: value.disabledDeseletionChannels,
    maximumChannelDeselection: value.maximumChannelDeselection,
  };
}
