import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Divider, Form, formatNumber } from "@ster/ster-toolkit";
import { Alert, Form as AntForm } from "antd";
import { Rule } from "antd/lib/form";
import { Store } from "antd/lib/form/interface";
import classNames from "classnames";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  CalculationResult,
  MediumEnum,
  Package,
  PackageChoiceEnum,
  PackageType,
} from "../../../../api";
import { settingsSelector } from "../../../../shared/selectors";
import { omit } from "../../../../utils";
import { getSpecificDaysInsidePeriod } from "../../../../utils/dateHelper";
import { allDaysOfTheWeek } from "../../../breakselect/constants";
import { FormReadOnlyItem } from "../../../partials/Form";
import PriceIndexTooltip from "../../../partials/Tooltips/PriceIndexTooltip/PriceIndexTooltip";
import PriceIndexTooltipOnline from "../../../partials/Tooltips/PriceIndexTooltip/PriceIndexTooltipOnline";
import CalculateGrpBudget from "./CalculateGrpBudget";
import styles from "./GrpBudget.module.less";
import { BudgetProps } from "./models";

/**
 * Invoeren van het aantal GRP's en Budget voor een pakket
 */
const GrpBudget = memo(
  ({
    subOrder,
    packages,
    spotIndexes,
    onChange,
    productId,
    medium,
    onFinishFailed,
    calculateTimeStamp,
    disabled,
    appendFormItem,
    requestDate,
  }: BudgetProps) => {
    const { i18n } = useLingui();
    const [form] = AntForm.useForm();
    const [changedField, setChangedField] = useState<string>("");
    const [loadingCalculation, setLoadingCalculation] = useState(false);
    const impressions = AntForm.useWatch("impressions", form);
    const { packageChoice, daysOfWeek, period } = subOrder;
    const settings = useSelector(settingsSelector);

    const isDisabled = useMemo(
      () => loadingCalculation || disabled,
      [disabled, loadingCalculation]
    );

    const selectedPackage = useMemo(
      () =>
        packages.find((p) => p.code === subOrder._package?.code) ??
        ({} as Package),
      [packages, subOrder._package?.code]
    );

    const selectedTargetGroup = selectedPackage.targetGroups?.find(
      (g) => g.id === subOrder.targetGroup?.id
    );

    const spotLengthIndex = spotIndexes?.find(
      (s) => s.length === subOrder.spotLength?.reduce((a, b) => a + (b ?? 0), 0)
    )?.index;

    useEffect((): void => {
      // Vul het formulier met de bestaande deelorder
      form.setFieldsValue({
        ...subOrder,
      });
    }, [subOrder, form]);

    const handleActivateField = useCallback(
      (
        event:
          | React.MouseEvent<HTMLInputElement>
          | React.FocusEvent<HTMLInputElement>
      ) => {
        const name =
          (event as React.FocusEvent<HTMLInputElement>)?.target.name ??
          event.currentTarget.name;
        if (changedField === name) {
          return;
        }
        setChangedField(name);
      },
      [changedField]
    );

    const handleDeactivateField = useCallback(() => {
      setChangedField("");
    }, []);

    const handleValuesChange = useCallback(
      (_: unknown, values: Store) => {
        onChange({ id: subOrder.id, ...values });
      },
      [onChange, subOrder.id]
    );

    const handleCalculated = useCallback(
      (values: CalculationResult) => {
        setLoadingCalculation(false);
        const changed = omit(values as Record<string, unknown>, [changedField]);
        handleValuesChange(null, changed);
      },
      [changedField, handleValuesChange]
    );

    // Alleen voor radio spots per dag
    const showSpotsPerDay = useMemo(
      () => medium === MediumEnum.Radio,
      [medium]
    );

    // Voor radio combi pakketten met spreiding en het Tour de France pakket tonen we een melding dat het aantal spots per dag een indicatie is
    const spotsPerDayIsIndication = useMemo(
      () =>
        (selectedPackage.hasSpread && selectedPackage.isCombi) ||
        selectedPackage.type === PackageType.TourDeFrance,
      [selectedPackage.hasSpread, selectedPackage.isCombi, selectedPackage.type]
    );

    // Manual trigger when dependent values change
    useEffect(() => {
      if (impressions && (daysOfWeek || period)) {
        form.validateFields(["impressions"]);
      }
    }, [form, daysOfWeek, impressions, period]);

    // Impressions maximum allowed validator
    const impressionsValidatorRules = useMemo(
      () => [
        {
          validator: async (_, value): Promise<void> => {
            if (!value) {
              throw new Error(
                i18n._(t`Vul het gewenste aantal impressies of budget in`)
              );
            }

            if (packageChoice !== PackageChoiceEnum.Display) {
              return;
            }

            // Validate maximum number of impressions
            const formPeriod = form.getFieldValue("period");
            const formDaysOfWeek = form.getFieldValue("daysOfWeek");

            let inputDaysOfWeek =
              formDaysOfWeek?.map((dayOfWeek: number) => dayOfWeek + 1) ?? [];
            if (inputDaysOfWeek.length === 0) {
              inputDaysOfWeek = allDaysOfTheWeek;
            }

            const daysInsidePeriod = getSpecificDaysInsidePeriod(
              formPeriod.from,
              formPeriod.to,
              inputDaysOfWeek
            ).length;
            const maximumDailyImpressions =
              settings.settings?.digitalDisplayMaximumImpressionsPerDay ??
              Number.MAX_SAFE_INTEGER;
            const maxNrOfImpressions =
              maximumDailyImpressions * daysInsidePeriod;
            if (value > maxNrOfImpressions) {
              throw new Error(
                i18n._(
                  t`Het maximum aantal impressies voor deze periode is ${formatNumber(maxNrOfImpressions, 0)}.`
                )
              );
            }
          },
        } as Rule,
      ],
      [
        form,
        settings.settings?.digitalDisplayMaximumImpressionsPerDay,
        i18n,
        packageChoice,
      ]
    );

    return (
      <>
        <Divider />
        <Form
          layout="inline"
          className={styles.budget}
          form={form}
          onValuesChange={handleValuesChange}
          name={`budget-${subOrder.id}`}
          onFinishFailed={onFinishFailed}
        >
          {appendFormItem}
          {(subOrder.grp ?? 0) < (selectedPackage.minGrp ?? 0) && (
            <Form.Item
              name="grpWarning"
              rules={[
                {
                  validator: (): Promise<void> =>
                    Promise.reject(
                      new Error(
                        i18n._(
                          t`Je pakket voldoet niet aan het minimale aantal GRP's.`
                        )
                      )
                    ),
                },
              ]}
            >
              <Alert
                showIcon
                className={styles.minGrpWarning}
                type="warning"
                message=""
                description={i18n._(
                  t`Je dient minimaal ${selectedPackage.minGrp} GRP's af te nemen`
                )}
              />
            </Form.Item>
          )}

          <div className={styles.budgetInput}>
            {(medium === MediumEnum.Radio || medium === MediumEnum.Tv) &&
              selectedTargetGroup &&
              subOrder.period && (
                <FormReadOnlyItem
                  label={i18n._(t`Outputprijs`)}
                  value={
                    <PriceIndexTooltip
                      pckg={selectedPackage}
                      targetGroup={selectedTargetGroup}
                      spotLengthIndex={spotLengthIndex ?? 0}
                      period={subOrder.period}
                      size="small"
                      medium={medium}
                    />
                  }
                />
              )}
            {medium === MediumEnum.Inter && subOrder.period && (
              <FormReadOnlyItem
                label={i18n._(t`Outputprijs`)}
                value={
                  <PriceIndexTooltipOnline
                    pckg={selectedPackage}
                    format={subOrder.format}
                    period={subOrder.period}
                    size="small"
                  />
                }
              />
            )}
            {(medium === MediumEnum.Radio || medium === MediumEnum.Tv) && (
              <Form.Item label={i18n._(t`GRP's`)}>
                <Form.Item
                  name="grp"
                  rules={[
                    {
                      validator: (_, value): Promise<void> =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                i18n._(
                                  t`Vul het gewenste aantal GRP's of budget in`
                                )
                              )
                            ),
                    },
                  ]}
                >
                  <CalculateGrpBudget
                    subOrder={subOrder}
                    medium={medium}
                    productId={productId}
                    onCalculated={handleCalculated}
                    onClick={handleActivateField}
                    onBlur={handleDeactivateField}
                    onLoading={setLoadingCalculation}
                    disabled={isDisabled && changedField !== "grp"}
                    isActive={changedField === "grp"}
                    className={classNames({ [styles.readOnly]: disabled })}
                    requestDate={requestDate}
                    name="grp"
                  />
                </Form.Item>
              </Form.Item>
            )}
            {medium === MediumEnum.Inter && (
              <Form.Item label={i18n._(t`Impressies`)}>
                <Form.Item
                  name="impressions"
                  dependencies={["budget"]}
                  rules={impressionsValidatorRules}
                >
                  <CalculateGrpBudget
                    subOrder={subOrder}
                    medium={medium}
                    productId={productId}
                    onCalculated={handleCalculated}
                    onClick={handleActivateField}
                    onBlur={handleDeactivateField}
                    onLoading={setLoadingCalculation}
                    disabled={isDisabled && changedField !== "impressions"}
                    isActive={changedField === "impressions"}
                    className={classNames({ [styles.readOnly]: disabled })}
                    requestDate={requestDate}
                    name="impressions"
                  />
                </Form.Item>
              </Form.Item>
            )}
            <Form.Item label={i18n._(t`Budget`)}>
              <Form.Item name="budget">
                <CalculateGrpBudget
                  subOrder={subOrder}
                  medium={medium}
                  productId={productId}
                  onCalculated={handleCalculated}
                  onClick={handleActivateField}
                  onBlur={handleDeactivateField}
                  onLoading={setLoadingCalculation}
                  disabled={isDisabled && changedField !== "budget"}
                  isActive={changedField === "budget"}
                  className={classNames({ [styles.readOnly]: disabled })}
                  requestDate={requestDate}
                  name="budget"
                  calculateTimeStamp={calculateTimeStamp}
                />
              </Form.Item>
            </Form.Item>
            {showSpotsPerDay && (
              <Form.Item label={i18n._(t`Spots/dag/zender`)}>
                <Form.Item name="spotsPerDayChannel">
                  <CalculateGrpBudget
                    subOrder={subOrder}
                    medium={medium}
                    productId={productId}
                    onCalculated={handleCalculated}
                    onClick={handleActivateField}
                    onBlur={handleDeactivateField}
                    onLoading={setLoadingCalculation}
                    disabled={
                      !selectedPackage.hasSpread ||
                      (isDisabled && changedField !== "spotsPerDayChannel")
                    }
                    isActive={changedField === "spotsPerDayChannel"}
                    requestDate={requestDate}
                    name="spotsPerDayChannel"
                  />
                </Form.Item>
                {!selectedPackage.hasSpread && (
                  <div className={styles.spotsPerDayChannelWarning}>
                    <Trans>Alleen i.c.m spreidingspakket</Trans>
                  </div>
                )}
                {spotsPerDayIsIndication && (
                  <div className={styles.spotsPerDayChannelWarning}>
                    <Trans>Let op! Dit is een indicatie</Trans>
                  </div>
                )}
              </Form.Item>
            )}
          </div>
        </Form>
      </>
    );
  }
);

export default GrpBudget;
