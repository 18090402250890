import { Spinner } from "@ster/ster-toolkit";
import { Form as AntForm } from "antd";
import { Store } from "antd/lib/form/interface";
import { isAfter } from "date-fns";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  GroupedPackageRadio,
  GroupedPackageTv,
  MediumEnum,
  Package,
  PackagePropertiesRadio,
  PackagePropertiesTv,
  SubOrderRequest,
  UpdateFields,
} from "../../../api";
import { ReduxStoreState } from "../../../store/base";
import {
  receiveGroupedPackagesRadioAction,
  receiveGroupedPackagesTvAction,
  receiveSpotIndexesAction,
} from "../../../store/campaignCreate/actions";
import { StoreModel } from "../../../store/models";
import {
  removeAndSortDuplicateDates,
  subOrderGenericFieldsFilled,
} from "../../../utils";
import {
  filterFullWeeks,
  getSpecificDaysInsidePeriod,
} from "../../../utils/dateHelper";
import { emptySelectedBreaks } from "../../breakselect/constants";
import DynamicIndexWarning from "../DynamicIndexWarning";
import Top2000Warning from "../Top2000Warning";
import GrpSubOrderForm from "./GrpSubOrderForm";
import { SubOrderEditProps } from "./models";
import BreakSelectionWithBudget from "./partials/BreakSelectionWithBudget";
import GrpBudget from "./partials/GrpBudget";
import { isPackageOptionDisabled } from "./partials/PackageFunctions";
import { grpSubOrderSelector } from "./selectors";
import styles from "./SubOrder.module.less";

const initialPackageOptionsRadio = {
  Spread: true,
  Hours: false,
  Combi: false,
  ChannelChoice: false,
  Days: false,
  PreferredPosition: false,
};

const initialPackageOptionsTv = {
  Spread: true,
  Premium: true, // Premium is de 2023 optie voor spreiding
  PreferredPosition: false,
  Hotspot: false,
};

/**
 * Toevoegen/wijzigen van een pakket van het type GRP
 */
const GrpSubOrder = memo(
  ({
    onChange,
    subOrder,
    subOrderOriginal,
    productId,
    onFinishFailed,
    period,
    medium,
    selectedSalesPeriod,
    subOrders,
    alreadySelected,
    isEditCampaign,
    requestDate,
  }: SubOrderEditProps) => {
    const [form] = AntForm.useForm();
    const dispatch = useDispatch();
    const [groupedPackage, setGroupedPackage] = useState<
      GroupedPackageRadio | GroupedPackageTv
    >();
    const [
      groupedPackagesTvWithoutSalesLock,
      setgroupedPackagesTvWithoutSalesLock,
    ] = useState<PackagePropertiesTv[] | undefined>(undefined);
    const [
      groupedPackagesRadioWithoutSalesLock,
      setgroupedPackagesRadioWithoutSalesLock,
    ] = useState<PackagePropertiesRadio[] | undefined>(undefined);

    const hasSpotLengthChanged = useMemo(
      () =>
        JSON.stringify(subOrder.spotLength) !==
        JSON.stringify(subOrderOriginal?.spotLength),
      [subOrder.spotLength, subOrderOriginal?.spotLength]
    );
    const isYourTimeOriginal = useMemo(
      () => subOrderOriginal?._package?.isYourTime,
      [subOrderOriginal?._package?.isYourTime]
    );

    const {
      spotIndexes,
      loading,
      packages,
      groupedPackagesRadio,
      groupedPackagesTv,
      canRequest,
      states: {
        spotIndexes: spotIndexesState,
        groupedPackagesRadio: groupedPackagesRadioState,
        groupedPackagesTv: groupedPackagesTvState,
      },
    } = useSelector((state: StoreModel) =>
      grpSubOrderSelector(state, { period, medium })
    );

    const selectedPackage = useMemo(
      () => packages?.find((p) => p.code === subOrder._package?.code),
      [packages, subOrder]
    );

    const enabledDaysOfWeek = useMemo(
      () =>
        // Als het minimaal aantal dagen gelijk is aan het aantal toegestane dagen is er geen keuze
        selectedPackage?.minDays ===
        selectedPackage?.weekDays?.filter((s) => s === 1).length
          ? [0, 0, 0, 0, 0, 0, 0]
          : selectedPackage?.weekDays ?? [],
      [selectedPackage]
    );

    // Is het mogelijk om dagen van de week te wijzigen?
    const canChangeDaysOfWeek = useMemo(
      () => enabledDaysOfWeek.includes(1),
      [enabledDaysOfWeek]
    );

    // Haal spotindexen op
    useEffect(() => {
      if (spotIndexesState === ReduxStoreState.Initial) {
        dispatch(
          receiveSpotIndexesAction.request({
            medium,
            year: period.from.getFullYear(),
          })
        );
      }

      if (
        medium === MediumEnum.Radio &&
        groupedPackagesRadioState === ReduxStoreState.Initial
      ) {
        dispatch(
          receiveGroupedPackagesRadioAction.request({
            year: period.from.getFullYear(),
          })
        );
      }

      if (
        medium === MediumEnum.Tv &&
        groupedPackagesTvState === ReduxStoreState.Initial
      ) {
        dispatch(
          receiveGroupedPackagesTvAction.request({
            year: period.from.getFullYear(),
          })
        );
      }
    }, [
      dispatch,
      groupedPackagesRadioState,
      groupedPackagesTvState,
      medium,
      period.from,
      spotIndexesState,
    ]);

    // Bepaal of de budget invoer getoond dient te worden
    const showBudget = useCallback(
      () =>
        subOrderGenericFieldsFilled(subOrder) &&
        !selectedPackage?.hasBreakSelection,
      [selectedPackage?.hasBreakSelection, subOrder]
    );

    // Betaal of de spot-kiezer getoond dient te worden
    const showBreakSelection = useCallback(
      () =>
        subOrderGenericFieldsFilled(subOrder) &&
        selectedPackage?.hasBreakSelection,
      [selectedPackage?.hasBreakSelection, subOrder]
    );

    const packagePropertiesTvWithoutSalesLock = useCallback(
      (packageGroup: GroupedPackageTv) => {
        // Geef alle pakketten binnen een groep terug die geen sales lock hebben
        const packagesInGroup = packageGroup.properties.map((p) =>
          packages.find(({ code }) => code === p.code)
        );

        const packagesWithoutSalesLock = packagesInGroup
          .filter(
            (s) =>
              !s?.salesLockPeriods.find(
                (p) => p.from <= period.to && p.to >= period.from
              )
          )
          .filter((item): item is Package => !!item);

        return packagesWithoutSalesLock
          .map((p) =>
            packageGroup.properties.find(({ code }) => code === p.code)
          )
          .filter((item): item is PackagePropertiesTv => !!item);
      },
      [packages, period.from, period.to]
    );

    const packagePropertiesRadioWithoutSalesLock = useCallback(
      (packageGroup: GroupedPackageRadio) => {
        // Geef alle pakketten binnen een groep terug die geen sales lock hebben
        const packagesInGroup = packageGroup.properties.map((p) =>
          packages.find(({ code }) => code === p.code)
        );

        const packagesWithoutSalesLock = packagesInGroup
          .filter(
            (s) =>
              !s?.salesLockPeriods.find(
                (p) => p.from <= period.to && p.to >= period.from
              )
          )
          .filter((item): item is Package => !!item);

        return packageGroup.properties.filter((s) =>
          packagesWithoutSalesLock.find((p) => p.code === s.code)
        );
      },
      [packages, period.from, period.to]
    );

    useEffect((): void => {
      if (subOrder) {
        // Vind de naam de groupedPackage die het gekozen pakket bevat
        const packageGroup =
          medium === MediumEnum.Tv
            ? groupedPackagesTv.find(
                ({ properties }) =>
                  properties.find(
                    ({ code }) => code === subOrder._package?.code
                  ) !== undefined
              )
            : groupedPackagesRadio.find(
                ({ properties }) =>
                  properties.find(
                    ({ code }) => code === subOrder._package?.code
                  ) !== undefined
              );

        // Vul het formulier met de bestaande deelorder
        form.setFieldsValue({
          spotLength: [undefined], // Spotlength is altijd minimaal een leeg array om de control te initialiseren
          ...subOrder,
          period: subOrder.period
            ? [subOrder.period.from, subOrder.period.to]
            : undefined,
          targetGroupId: subOrder.targetGroup?.id,
          packageCode: subOrder._package?.code,
          packageGroup: packageGroup?.name,
        });

        // Trigger een validatie van de ingevulde velden. Vooral nodig voor de items met een checkbox group die anders niet valideren
        form.validateFields({ dirty: true });

        // Re-validate de spotlength
        const currentSpotLength = (
          (form.getFieldValue("spotLength") ?? []) as (number | undefined)[]
        ).filter((l) => l !== undefined);
        if (currentSpotLength.length > 0) {
          form.validateFields(["spotLength"]);
        }

        setGroupedPackage(packageGroup);
        if (packageGroup) {
          if (medium === MediumEnum.Radio) {
            setgroupedPackagesRadioWithoutSalesLock(
              packagePropertiesRadioWithoutSalesLock(
                packageGroup as GroupedPackageRadio
              )
            );
          } else {
            setgroupedPackagesTvWithoutSalesLock(
              packagePropertiesTvWithoutSalesLock(
                packageGroup as GroupedPackageTv
              )
            );
          }
        }
      }
    }, [
      form,
      groupedPackagesRadio,
      groupedPackagesRadioState,
      groupedPackagesTv,
      groupedPackagesTvState,
      medium,
      packagePropertiesRadioWithoutSalesLock,
      packagePropertiesTvWithoutSalesLock,
      subOrder,
    ]);

    const handleChangedPackageGroup = useCallback(
      (value: string) => {
        if (medium === MediumEnum.Tv) {
          // Wijzigen van een pakketgroep bij tv
          const packageGroup = groupedPackagesTv.find(
            ({ name }) => name === value
          );
          setGroupedPackage(packageGroup);

          if (packageGroup == null) {
            return {};
          }

          // Vind het pakket met de default opties onder de pakketgroep
          const packagesWithoutSalesLock =
            packagePropertiesTvWithoutSalesLock(packageGroup);
          const packageProperties = packagesWithoutSalesLock.find(
            ({ hasSpread, hasPreferredPosition, hasHotspot, hasPremium }) =>
              (hasSpread === initialPackageOptionsTv.Spread ||
                hasPremium === initialPackageOptionsTv.Premium) &&
              hasPreferredPosition ===
                initialPackageOptionsTv.PreferredPosition &&
              hasHotspot === initialPackageOptionsTv.Hotspot
          );
          // Als er geen pakket is met de standaard waarden nemen we de eerste uit de lijst
          return packageProperties?.code ?? packagesWithoutSalesLock[0].code;
        }
        // Wijzigen van een pakketgroep bij radio
        const packageGroup = groupedPackagesRadio.find(
          ({ name }) => name === value
        );
        setGroupedPackage(packageGroup);

        if (packageGroup == null) {
          return {};
        }

        // Vind het pakket met de default opties onder de pakketgroep
        const packagesWithoutSalesLock =
          packagePropertiesRadioWithoutSalesLock(packageGroup);

        const periodEnabledPackages = packagesWithoutSalesLock.filter(
          (x) =>
            isPackageOptionDisabled(
              packages.find((p) => p.code === x.code)!,
              subOrder.period ?? period,
              subOrder.excluded?.length ?? 0,
              subOrders?.filter((s) => s.id !== subOrder?.id)
            ).periodEnabled
        );

        const packageProperties = packagesWithoutSalesLock.find(
          ({
            hasSpread,
            hasHours,
            hasCombi,
            hasChannelChoice,
            hasDays,
            hasPreferredPosition,
          }) =>
            hasSpread === initialPackageOptionsRadio.Spread &&
            hasHours === initialPackageOptionsRadio.Hours &&
            hasCombi === initialPackageOptionsRadio.Combi &&
            hasChannelChoice === initialPackageOptionsRadio.ChannelChoice &&
            hasDays === initialPackageOptionsRadio.Days &&
            hasPreferredPosition ===
              initialPackageOptionsRadio.PreferredPosition
        );

        // Als het standaard pakket niet beschikbaar is qua periode nemen we het eerste pakket dat wel beschikbaar is
        if (
          periodEnabledPackages.some((s) => packageProperties?.code === s.code)
        ) {
          return packageProperties?.code;
        }

        return (
          periodEnabledPackages?.[0]?.code ?? packagesWithoutSalesLock[0].code
        );
      },
      [
        groupedPackagesRadio,
        groupedPackagesTv,
        medium,
        packagePropertiesRadioWithoutSalesLock,
        packagePropertiesTvWithoutSalesLock,
        packages,
        period,
        subOrder.excluded?.length,
        subOrder?.id,
        subOrder.period,
        subOrders,
      ]
    );

    // Wijzigen van het formulier
    const handleValuesChange = useCallback(
      (changedValues: Store, values: Store) => {
        const resetFields = [];
        const defaultValues: Partial<SubOrderRequest> = {};

        let packageCode = changedValues.packageCode || subOrder._package?.code;
        if (changedValues.packageGroup) {
          packageCode = handleChangedPackageGroup(changedValues.packageGroup);
        }

        if (changedValues.packageCode || changedValues.packageGroup) {
          const newPackage = packages?.find((p) => p.code === packageCode);
          const oldPackage = packages?.find(
            (p) => p.code === subOrder._package?.code
          );
          defaultValues._package = newPackage;

          // Als voor nieuwe pakket geen blokselectie nodig is
          if (!newPackage?.hasBreakSelection) {
            defaultValues.breakSelection = emptySelectedBreaks;
          }

          // Als het minimaal aantal dagen gelijk is aan het aantal toegestane dagen is er geen keuze
          if (
            newPackage &&
            newPackage.minDays ===
              newPackage?.weekDays?.filter((s) => s === 1).length
          ) {
            defaultValues.daysOfWeek = [0, 1, 2, 3, 4, 5, 6].filter(
              (key) => newPackage.weekDays?.[key] === 1
            );
          }

          if (newPackage?.targetGroups.length === 1) {
            // Als het pakket maar 1 doelgroep heeft selecteren we deze automatisch
            const targetGroup = newPackage?.targetGroups?.[0];
            defaultValues.targetGroup = targetGroup;
            defaultValues._package = newPackage;
            defaultValues.channels = targetGroup?.channels;
          } else if (
            !newPackage?.targetGroups.find(
              ({ intomartTargetGroupId, channels }) =>
                intomartTargetGroupId ===
                  subOrder.targetGroup?.intomartTargetGroupId &&
                JSON.stringify(channels) === JSON.stringify(subOrder.channels)
            )
          ) {
            // Als de eerder gekozen doelgroep niet bestaat in het nieuwe pakket resetten we het veld
            resetFields.push("targetGroupId", "targetGroup");
            defaultValues.breakSelection = [];
          }

          if (
            !defaultValues.daysOfWeek &&
            medium === MediumEnum.Radio &&
            newPackage &&
            newPackage.minDaysNoWeekly >= 4 &&
            newPackage.minDaysNoWeekly < 7 &&
            newPackage.weekDays
          ) {
            // only for Ster & Cultuur, 5-6 DAGEN, ALGEMEEN NUT (sturing) and 4+ days per week
            // packages: select all possible weekdays
            defaultValues.daysOfWeek = newPackage.weekDays
              .map((v, idx) => (v === 1 ? idx : undefined))
              .filter((v) => v !== undefined) as number[];
            resetFields.push("excluded");
          } else if (canChangeDaysOfWeek) {
            // reset to `null`
            resetFields.push("excluded", "daysOfWeek");
          } else {
            // reset to `null`
            resetFields.push("excluded");
          }

          if (
            !defaultValues.timeSelection &&
            !changedValues.timeSelection &&
            !values.hasHours
          ) {
            // reset
            resetFields.push("timeSelection");
          }

          if (
            (oldPackage &&
              !defaultValues.daysOfWeek &&
              oldPackage?.minDaysNoWeekly !== newPackage?.minDaysNoWeekly) ||
            JSON.stringify(oldPackage?.weekDays) !==
              JSON.stringify(newPackage?.weekDays)
          ) {
            // Reset de uitgesloten dagen als het minimum aantal dagen per week of beschikbare weekdagen wisselt
            resetFields.push("excluded", "daysOfWeek");
          }
        }

        if (changedValues.daysOfWeek && subOrder.period) {
          // Als de selectie wijzigt naar geen dagen van de week geselecteerd behandelen we dat als alle dagen geselecteerd
          // Anders is er geen onderscheid meer te maken met de uitgesloten weken
          let { daysOfWeek } = changedValues;
          if (changedValues.daysOfWeek.length === 0) {
            daysOfWeek = [0, 1, 2, 3, 4, 5, 6];
          }

          // Bij het wijzigen van de weekdagen vullen we de uitgesloten dagen
          // Als er volledige weken uitgesloten zijn blijven deze uitgesloten
          defaultValues.excluded = removeAndSortDuplicateDates([
            ...getSpecificDaysInsidePeriod(
              subOrder.period.from,
              subOrder.period.to,
              [1, 2, 3, 4, 5, 6, 7].filter(
                (key) =>
                  daysOfWeek.find((day: number) => day === key - 1) ===
                  undefined
              )
            ),
            ...filterFullWeeks(subOrder.excluded ?? []),
          ]);
        }

        if (changedValues.period) {
          // De spread property wordt gezet als de geselecteerde datum na de einddatum van de verkoopperiode ligt
          defaultValues.spread = isAfter(
            values.period[1],
            selectedSalesPeriod.salesPeriodEndDate
          );
          defaultValues.period = {
            from: values.period[0],
            to: values.period[1],
          };

          // Reset de uitgesloten dagen als de periode wijzigt
          resetFields.push("excluded");

          if (canChangeDaysOfWeek) {
            // Reset de uitgesloten dagen als de periode wijzigt
            resetFields.push("daysOfWeek");
          }
        }

        if (changedValues.targetGroupId) {
          defaultValues.targetGroup = {
            ...selectedPackage?.targetGroups?.find(
              (g) => g.id === values.targetGroupId
            ),
          };

          defaultValues.channels = selectedPackage?.targetGroups?.find(
            (g) => g.id === values.targetGroupId
          )?.channels;

          // Reset de spotselectie als de doelgroep wijzigt
          defaultValues.breakSelection = [];
        }

        if (changedValues.spotLength) {
          // Bij het wijzigen van de spotlengte komt alleen het gewijzigde veld door, dus we moeten de andere waardes ook toevoegen
          defaultValues.spotLength = values.spotLength;
        }

        // Voer een herberekening uit
        if (
          subOrder.budget &&
          (changedValues.channels ||
            values.spotLength?.filter(Boolean).join("+") !==
              subOrder.spotLength?.filter(Boolean).join("+") ||
            changedValues.targetGroupId ||
            changedValues.daysOfWeek ||
            changedValues.packageCode ||
            changedValues.packageGroup ||
            changedValues.timeSelection ||
            changedValues.excluded ||
            changedValues.period)
        ) {
          // Vul het veld calculateTimeStamp met de huidige timestamp om een herberekening te forceren
          form.setFieldsValue({ calculateTimeStamp: +new Date() });
        }

        const newValues: Partial<SubOrderRequest> = {
          ...subOrder,
          ...changedValues,
          ...Object.assign(
            // Alle velden die gereset moeten worden krijgen de waarde null
            {},
            ...resetFields.map((s) => ({ [s]: null }))
          ),
          ...defaultValues,
        };
        form.resetFields(resetFields);
        onChange(newValues);
      },
      [
        canChangeDaysOfWeek,
        form,
        handleChangedPackageGroup,
        medium,
        onChange,
        packages,
        selectedPackage?.targetGroups,
        selectedSalesPeriod.salesPeriodEndDate,
        subOrder,
      ]
    );

    // Afhandelen van het optreden van een error bij het versturen van het formulier
    const handleFinishFailed = useCallback(() => {
      onFinishFailed(subOrder.id ?? 0);
    }, [onFinishFailed, subOrder]);

    const showPackageOptions = useMemo(
      () => groupedPackage && groupedPackage.properties.length > 1,
      [groupedPackage]
    );

    const canUpdateField = useCallback(
      (updateField: UpdateFields) =>
        !isEditCampaign ||
        (subOrder.editableFields?.includes(updateField) ?? false),
      [isEditCampaign, subOrder.editableFields]
    );

    const isYourTimeEditSpotLength = useMemo(
      () =>
        subOrder._package?.isYourTime &&
        hasSpotLengthChanged &&
        isYourTimeOriginal,
      [hasSpotLengthChanged, isYourTimeOriginal, subOrder._package?.isYourTime]
    );

    return (
      <Spinner spinning={loading}>
        <GrpSubOrderForm
          enabledDaysOfWeek={enabledDaysOfWeek}
          form={form}
          groupedPackagesRadio={groupedPackagesRadio}
          groupedPackagesTv={groupedPackagesTv}
          handleChange={handleValuesChange}
          handleFinishFailed={handleFinishFailed}
          medium={medium}
          packagePropertiesRadioWithoutSalesLock={
            groupedPackagesRadioWithoutSalesLock
          }
          packagePropertiesTvWithoutSalesLock={
            groupedPackagesTvWithoutSalesLock
          }
          packages={packages}
          period={period}
          productId={productId}
          selectedSalesPeriod={selectedSalesPeriod}
          spotIndexes={spotIndexes}
          subOrder={subOrder}
          subOrders={subOrders}
          alreadySelected={alreadySelected}
          groupedPackage={groupedPackage}
          selectedPackage={selectedPackage}
          showPackageOptions={showPackageOptions}
          canUpdatePeriod={
            canUpdateField(UpdateFields.StartDate) &&
            canUpdateField(UpdateFields.EndDate)
          }
          canUpdateSpotLength={canUpdateField(UpdateFields.SpotLength)}
          canUpdatePackage={canUpdateField(UpdateFields.Package)}
          showBlockEditMessage={isYourTimeEditSpotLength}
        />

        {subOrder.period && (
          <Top2000Warning
            className={styles.top2000}
            medium={medium}
            {...subOrder.period}
            {...subOrder}
          />
        )}

        {showBreakSelection() && (
          <BreakSelectionWithBudget
            subOrder={subOrder}
            medium={medium}
            productId={productId}
            onFinishFailed={handleFinishFailed}
            onChange={onChange}
            canSelectPreferredPosition={
              selectedPackage?.canSelectPreferredPosition ?? false
            }
            preferredPositionSurcharge={
              selectedPackage?.preferredPositionSurcharge ?? 0
            }
            canSelectBlocks={canRequest && !isYourTimeEditSpotLength}
            calculateTimeStamp={form.getFieldValue("calculateTimeStamp")}
            minGrp={selectedPackage?.minGrp}
            spotIndexes={spotIndexes}
            packages={packages}
            alreadySelected={alreadySelected}
            showBlocksUnavailableMessage={!canRequest}
          />
        )}

        {subOrder.period && (
          <DynamicIndexWarning
            className={styles.dynamicIndex}
            medium={medium}
            {...subOrder.period}
            {...subOrder}
          />
        )}

        {showBudget() && (
          <GrpBudget
            subOrder={subOrder}
            medium={medium}
            packages={packages}
            spotIndexes={spotIndexes}
            onChange={onChange}
            productId={productId}
            onFinishFailed={handleFinishFailed}
            calculateTimeStamp={form.getFieldValue("calculateTimeStamp")}
            requestDate={requestDate}
            disabled={!canUpdateField(UpdateFields.Budget)}
          />
        )}
      </Spinner>
    );
  }
);

export default GrpSubOrder;
