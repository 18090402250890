import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Channel, ChannelNames, Checkbox, Form } from "@ster/ster-toolkit";
import { memo, useCallback } from "react";

import { RadioShortChannelNames } from "../../../partials/Channel/Channel";
import styles from "./ChannelPicker.module.less";
import { ChannelPickerProps } from "./models";

export const allRadioChannels: string[] = [
  "R1",
  "R2",
  "R3",
  "R4",
  "R5",
  "FX",
  "RR",
];

const ChannelPicker = memo(
  ({
    channels,
    disabledChannels,
    value: selectedChannels,
    maximumDeselection,
  }: ChannelPickerProps) => {
    const { i18n } = useLingui();

    const channelValidator = useCallback(
      (value?: string[]) => {
        if ((value?.length ?? 0) === 0) {
          return Promise.reject(new Error(i18n._(t`Kies minimaal 1 zender`)));
        }

        if (!maximumDeselection) {
          return Promise.resolve();
        }

        const isValidSelection =
          channels.length - (value?.length ?? 0) <= maximumDeselection;

        return isValidSelection
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                i18n._(
                  t`Je mag maximaal ${maximumDeselection} zenders(s) uitsluiten`
                )
              )
            );
      },
      [channels.length, i18n, maximumDeselection]
    );

    const isChannelDisabled = useCallback(
      (channel: string) => {
        if (disabledChannels?.includes(channel)) {
          return true;
        }

        if (!selectedChannels?.includes(channel)) {
          return false;
        }

        if (maximumDeselection) {
          // Als het maximaal aantal zenders is uitgesloten dan zijn de andere zenders disabled
          return (
            channels.length - (selectedChannels?.length ?? 0) ===
            maximumDeselection
          );
        }

        return false;
      },
      [channels.length, disabledChannels, maximumDeselection, selectedChannels]
    );

    return (
      <Form.Item
        name="channels"
        label={i18n._(t`Zenders`)}
        rules={[
          {
            validator: (_, value): Promise<void> => channelValidator(value),
          },
        ]}
        helpText={
          <>
            <Trans>
              Selecteer de zenders waarop de campagne wordt uitgezonden.
            </Trans>
            {maximumDeselection === 1 && (
              <>
                <br />
                <Trans>Je kunt NPO Radio 1 of NPO Klassiek uitsluiten.</Trans>
              </>
            )}
          </>
        }
      >
        <Checkbox.Group>
          {channels.map((channel) => (
            <Checkbox
              value={channel}
              key={channel}
              className={styles.checkbox}
              disabled={isChannelDisabled(channel)}
            >
              <Channel
                type={
                  RadioShortChannelNames.find(({ key }) => key === channel)
                    ?.value as ChannelNames
                }
              />
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    );
  }
);

export default ChannelPicker;
