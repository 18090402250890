import { Form } from "@ster/ster-toolkit";
import { FormItemProps, FormProps } from "antd/lib/form";
import { PropsWithChildren } from "react";

import styles from "./Form.module.less";

export interface FormReadOnlyItemProps extends FormItemProps {
  value?: string | React.ReactElement | React.ReactElement[] | null;
  hideIfEmpty?: boolean;
}

export interface FormEditableItemProps extends FormReadOnlyItemProps {
  editable: boolean;
}

export const FormReadOnlyItem = ({
  value,
  hideIfEmpty = true,
  className,
  ...props
}: FormReadOnlyItemProps): React.ReactElement | null =>
  !hideIfEmpty || value ? (
    <Form.Item {...props} className={`${styles.readOnly} ${className ?? ""}`}>
      <span>{value}</span>
    </Form.Item>
  ) : null;

export const formItemLayout: FormProps = {
  layout: "horizontal",
  className: "fixedWidthLabels",
  scrollToFirstError: true,
};

export const FormEditableItem = ({
  editable,
  children,
  ...props
}: PropsWithChildren<FormEditableItemProps>): React.ReactElement | null => (
  <>
    {!editable && <FormReadOnlyItem {...props} />}
    {editable && children}
  </>
);
